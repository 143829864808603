<template>
  <div>
    <PasswordReset
      v-if="showPasswordReset"
      @close="togglePasswordReset()"
    ></PasswordReset>
    <section>
      <v-container class="fill-height py-8" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <div class="v-responsive" style="max-width: 700px">
              <div class="v-responsive__content">
                <div class="text-overline secondary--text mb-3">Mira One</div>
                <h2 class="text-h3 mb-6">Anmelden</h2>
              </div>
            </div>
            <v-card max-width="600px" class="pa-4">
              <v-card-text>
                <v-form v-model="formIsValid" @submit.prevent>
                  <v-text-field
                    id="email"
                    v-model.trim="loginForm.email"
                    label="E-Mail-Adresse"
                    outlined
                    type="email"
                    autocomplete="email"
                    :rules="[rules.required, rules.emailFormat]"
                    placeholder="name@email.de"
                  ></v-text-field>
                  <v-text-field
                    id="password"
                    v-model.trim="loginForm.password"
                    label="Passwort"
                    outlined
                    type="password"
                    autocomplete="current-password"
                    :rules="[rules.required]"
                    placeholder="••••••••••••••••"
                  ></v-text-field>
                  <div class="pt-3 pb-6 body-2">
                    Mit deiner Anmeldung bestätigst Du, dass Du unsere
                    <a
                      target="_blank"
                      href="https://docs.mira-one.com/agb"
                      @click.stop
                      >Allgemeinen Geschäftsbedingungen</a
                    >
                    und
                    <a
                      target="_blank"
                      href="https://docs.mira-one.com/datenschutz"
                      @click.stop
                      >Datenschutzerklärung</a
                    >
                    gelesen und akzeptiert sowie in die Verarbeitung
                    personenbezogener Daten eingewilligt hast.
                  </div>
                </v-form>
                <!-- color="#2a4f66" -->
                <v-btn
                  color="red darken-1"
                  :disabled="!formIsValid"
                  :loading="loading"
                  block
                  x-large
                  depressed
                  @click="login()"
                  class="white--text"
                >
                  Anmelden
                </v-btn>
                <div class="text-center my-3 text-subtitle-1">
                  <router-link
                    :to="{ name: 'auth-register' }"
                    class="text-decoration-none"
                    style="color: grey"
                    >Du hast noch kein Konto?</router-link
                  >
                </div>
                <div class="text-center">
                  <v-btn
                    class="uppercase"
                    plain
                    small
                    @click="togglePasswordReset()"
                    >Passwort vergessen</v-btn
                  >
                </div>
              </v-card-text>
              <v-card-text v-if="errorMsg !== ''">
                <v-alert dense text type="error"> {{ errorMsg }} </v-alert>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { USER } from "@/store/modules.js";
import { SIGN_IN } from "@/store/action-types.js";
import PasswordReset from "@/components/Auth/PasswordReset";
import { handleAuthError } from "@/util/firebase/FirebaseErrorHandling.js";

export default {
  name: "auth-login",
  components: {
    PasswordReset,
  },
  data() {
    return {
      loginForm: {
        email: "",
        password: "",
      },
      showPasswordReset: false,
      loading: false,
      errorMsg: "",
      checkbox: false,
      formIsValid: false,

      rules: {
        emailFormat: (value) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
          ) || "Ungültige E-Mail-Adresse",
        required: (value) => !!value || "Erforderlich",
      },
    };
  },

  methods: {
    togglePasswordReset() {
      this.showPasswordReset = !this.showPasswordReset;
    },
    login() {
      if (this.formIsValid) {
        // Dispatch to Vuex to handle user login
        this.loading = true;
        this.$store
          .dispatch(`${USER}${SIGN_IN}`, {
            email: this.loginForm.email,
            password: this.loginForm.password,
          })
          .catch((error) => {
            this.errorMsg = handleAuthError(error.code);
            console.log(error.code, error.message);
            this.loading = false;
          });
      } else {
        alert("Fehler: Prüfe Deine Eingabe.");
      }
    },
  },
};
</script>

<style>
.uppercase {
  text-transform: uppercase;
  letter-spacing: 0.0892857143em;
}
</style>
